import { useEffect, useState } from 'react';
import { db } from '../index';

const GetWriting = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let current = true;
    const getContent = async () => {
      setLoading(true)
      const writingCollection = await db.collection('writing').get()
      const documents = await Promise.all(
        writingCollection.docs.map(async doc => {
          return { ...doc.data(), key: doc.id }
        })
      )
      setData(documents)
      setLoading(false)
    }
    if (current) {
      getContent()
    }
    return () => {
      current = false
    }
  }, []);
  return { data, loading }
}

export { GetWriting }