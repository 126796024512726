import { Box } from "grommet";
import { VerticalTab } from "../components";
import { GetWriting } from "../api";

const Writing = () => {
  const { data, loading } = GetWriting();
  return (
    <Box fill>
      <VerticalTab tabs={data} loadin={loading} />
    </Box>
  );
};
export { Writing };
