import React from "react";
import { Box, Heading, Anchor, ResponsiveContext } from "grommet";
import { useHistory } from "react-router-dom";
import { logEvent } from "../hooks";

const NavBar = (props) => {
  const { push } = useHistory();
  const onClick = ({ route }) => {
    logEvent({ event: `navigate_to_${route}`});
    push(`/${route}`);
  };
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          tag="header"
          direction={size !== "small" ? "row" : "column"}
          align="center"
          justify={size !== "small" ? "between" : "evenly"}
          background="light-2"
          pad={{ left: "medium", right: "small", vertical: "small" }}
          style={{ zIndex: "1" }}
          height="xsmall"
        >
          <Heading
            level={size === "small" ? "3" : "2"}
            margin="none"
            onClick={() => onClick({ route: "about" })}
            color="title"
            style={{ cursor: "pointer" }}
          >
            Maeve Allsup
          </Heading>
          <Box tag="routes" direction="row" justify="center" align="center">
            <Anchor
              size="small"
              label="Writing"
              margin={{ right: "small" }}
              onClick={() => onClick({ route: "writing" })}
            />
            {/* <Anchor size="small" label="Multimedia" margin={{ right: 'small' }} /> */}
            <Anchor
              size="small"
              label="About"
              margin={{ right: "small" }}
              onClick={() => onClick({ route: "about" })}
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export { NavBar };
