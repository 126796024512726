import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/analytics'

var firebaseConfig = {
  apiKey: "AIzaSyCaXsGeV16qUlw9nDhewUdRVLI5UFqwlx4",
  authDomain: "maeve-6e76b.firebaseapp.com",
  databaseURL: "https://maeve-6e76b.firebaseio.com",
  projectId: "maeve-6e76b",
  storageBucket: "maeve-6e76b.appspot.com",
  messagingSenderId: "909535098146",
  appId: "1:909535098146:web:60fee6472a5a26b5f6217b",
  "measurementId": "G-T91VKQXRJP"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const analytics = firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
