import React, { useEffect } from "react";
import { Box, Grommet } from "grommet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NavBar, Footer } from "./components";
import { Writing, About } from "./pages";
import Theme from "./Theme.json";

function App() {
  return (
    <Grommet theme={Theme} full themeMode="light">
      <Router>
        <Box fill="vertical">
          <NavBar />
          <Box basis="full">
            <Switch>
              <Route exact path="/writing">
                <Writing />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/">
                <About />
              </Route>
            </Switch>
            <Footer />
          </Box>
        </Box>
      </Router>
    </Grommet>
  );
}

export default App;
