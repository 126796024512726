import React, { useEffect, useState } from "react";
import { Box, Button, Text, Main, Grid } from "grommet";
import { logEvent, useSize } from "../hooks";

const VerticalTab = ({ tabs, loading }) => {
  const [selectedTab, setTab] = useState("");
  const content =
    tabs.filter((tab) => tab.key === selectedTab)[0]?.content || [];
  useEffect(() => {
    setTab(tabs[0]?.key);
  }, [tabs]);
  const onTabChange = (tab) => {
    setTab(tab.key);
    logEvent({ event: `click_on_tab_${tab.label}` });
  };
  const onItemClick = (item) => {
    logEvent({ event: `open_link_for_${item.title}`, params: { ...item } });
    window.open(item.link, "_blank");
  };
  const size = useSize();
  return (
    <Box
      direction={size !== "small" ? "row" : "column"}
      justify="start"
      align="start"
      gap="medium"
      fill
      overflow="scroll"
    >
      <Box
        direction={size === "small" ? "row" : "column"}
        width="small"
        margin="medium"
        justify="start"
        gap="small"
        wrap={true}
        fill={size === "small" ? "horizontal" : "vertical"}
      >
        {tabs
          .sort((a, b) => a.order - b.order)
          .map((tab) => (
            <Button
              plain
              round="small"
              secondary
              color={tab.key === selectedTab ? "brand" : "black"}
              label={tab.label}
              key={tab.key}
              active={tab.key === selectedTab}
              onClick={() => onTabChange(tab)}
            />
          ))}
      </Box>
      <Box pad="medium" overflow="scroll">
        <Grid
          rows={{
            count: content.length,
            size: "auto",
          }}
          gap="small"
        >
          {content.map((item) => (
            <Box
              style={{ cursor: "pointer" }}
              key={item.link}
              elevation="none"
              direction="column"
              pad="small"
              onClick={() => onItemClick(item)}
            >
              <Text weight="bold" size="small">
                {item.title}
              </Text>
              <Text style={{ fontStyle: "italic" }} size="small">
                {item.outlet}
              </Text>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { VerticalTab };
