import React from 'react';
import { GetAbout } from '../api';
import { Box, Paragraph, Image, Main } from 'grommet';
import PhotoUrl from '../assets/Maeve-1.jpeg';
import { useSize } from '../hooks';

const About = () => {
  const { data, loading } = GetAbout();
  const size = useSize();
  return (
    <Box fill>
      <Box
        direction={size === 'small' ? "column" : "row"}
        justify="start"
        pad="large"
        align="start"
        gap="medium"
        margin={{ bottom: 'medium' }}
        overflow={size === "small" && "auto"}
      >
        <Box width="medium" height="medium" fill={size === "small"} margin={{ top: "medium" }} >
          <Image src={PhotoUrl} fit="cover" />
        </Box>
        <Box>
          {data?.content?.map((paragraph, index) => (
            <Paragraph key={`paragraph-${index}`} size="small">{paragraph}</Paragraph>)
          )}
        </Box>
      </Box>
    </Box>
  )
}

export { About }