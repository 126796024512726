import React from "react";
import { ResponsiveContext } from "grommet";
import { analytics } from "../index";

const logEvent = ({ event: eventName, params: eventParams }) => {
  analytics.logEvent(eventName, eventParams);
};

const useSize = () => {
  const size = React.useContext(ResponsiveContext);
  return size;
};

export { useSize, logEvent };
