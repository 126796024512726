import { useEffect, useState } from 'react';
import { db } from '../index';

const GetAbout = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let current = true;
    const getContent = async () => {
      setLoading(true)
      const aboutDoc = await db.collection('about').doc('paragraphs');
      const document = await aboutDoc.get()
      setData(document.data())
      setLoading(false)
    }
    if (current) {
      getContent()
    }
    return () => {
      current = false
    }
  }, []);
  return { data, loading }
}

export { GetAbout }