import React from 'react';
import { Footer as GFooter } from 'grommet';
import { Linkedin, Twitter, Mail } from 'grommet-icons';
import { useHistory } from "react-router-dom";

const urls = {
  linkedin: 'https://www.linkedin.com/in/maeve-allsup-1a824b90',
  twitter: 'https://twitter.com/maevemallsup',
  mail: 'mailto:maeve.allsup@gmail.com'
}

const Footer = () => {
  const { push } = useHistory()
  return (
    <GFooter background="light-2" pad="medium" justify="center">
      <Linkedin size="small" onClick={() => window.open(urls.linkedin, "_blank")} />
      <Twitter size="small" onClick={() => window.open(urls.twitter, "_blank")} />
      <Mail size="small" onClick={() => window.open(urls.mail, "_blank")} />
    </GFooter>
  )
}

export { Footer }